<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <div class="app-max-width">
        <ion-toolbar class="text-center">
        
          <ion-buttons slot="start">
            <ion-button @click.prevent="goBack()">
              <ion-icon :icon="chevronBack"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-title class="text-capitalize">Send Message</ion-title>
        
        </ion-toolbar>
      </div>
    </ion-header>
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div class="app-max-width">
        <SearchProfiles @selected-users="getData" />
        <SendMessageForm :username="null" :selected-users="selectedUsers" />
      </div>
    </ion-content>
    <!-- Footer -->
    <ion-footer>
      <Tabs page="send-message" />
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon } from '@ionic/vue';
import { chevronBack, apps, reorderThree, options } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import doAuth from '../../services/auth';
import Tabs from '../../components/Tabs.vue';
import SearchProfiles from '../../components/SearchProfiles.vue';
import SendMessageForm from '../../components/SendMessageForm.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'CreateMessage',
  components: {
    IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon, Tabs, SendMessageForm, SearchProfiles
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();
    const selectedUsers = ref([]);

    function goBack() {
      router.go(-1)
    }

    function updateRoute(val) {
      router.push(val)
    }

    function getData(e) {
      selectedUsers.value = e
    }

    return {
      chevronBack, router, goBack, apps, reorderThree, options, updateRoute, getData, selectedUsers
    }
  },
});
</script>

<style scoped>

</style>