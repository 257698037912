<template>
	<!-- Input -->
	<div>
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="keyword" placeholder="Enter a name to send a message"></ion-input>
			<ion-button color="light" v-if="keyword != null && keyword != ''" @click.prevent="clearSearch()">
				<ion-icon :icon="close" color="dark"></ion-icon>
			</ion-button>
		</ion-item>
	</div>
	<!-- Results -->
	<div v-if="matchedItems.length > 0">
		<ion-list>
			<ion-item v-for="user in matchedItems" :key="user" @click.prevent="sendTo(user)" style="cursor:pointer;">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-avatar slot="start">
					<img v-if="user.profilePhoto != null && user.profilePhoto != ''" :src="user.profilePhoto">
					<img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
				</ion-avatar>
				<ion-label>
					<h2>{{user.name}}</h2>
					<!--<h3>Guitarist in metal band</h3>-->
					<p>{{user.location}}</p>
				</ion-label>
			</ion-item>
		</ion-list>
	</div>
	<!-- Selected Users -->
	<div>
		<ion-chip v-for="(user, index) in selectedUsers" :key="user">
			<ion-avatar>
				<img v-if="user.avatar != null && user.avatar != ''" :src="user.avatar">
				<img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
			</ion-avatar>
			<ion-label>{{user.name}}</ion-label>
			<ion-icon :icon="closeCircle" @click.prevent="removeUser(index)"></ion-icon>
		</ion-chip>
	</div>
</template>

<script>
import { loadingController, IonItem, IonInput, IonButton, IonIcon, IonChip, IonAvatar, IonLabel } from '@ionic/vue';
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { close, closeCircle } from 'ionicons/icons';

export default defineComponent({
	props: [],
	name: 'SearchProfiles',
	components: { IonItem, IonInput, IonButton, IonIcon, IonChip, IonAvatar, IonLabel },
	setup() {
	const router = useRouter();
	const store = useStore();
	const authUser = computed(() => store.state.authUser);
	const timeout = { default: 6000 }
	//const toastMessage = ref(null);
	const userCollection = ref([]);
	const keyword = ref(null);
	const matchedItems = ref([]);
	const selectedUsers = ref([]);

	onMounted(() => {
		getUserCollection()
	})

	async function presentLoading() {
		const loading = await loadingController
		.create({
			cssClass: 'my-custom-class',
			message: 'Please wait...',
			duration: timeout,
		});

		await loading.present();

		setTimeout(function() {
			loading.dismiss()
		}, timeout);
	}

	function getUserCollection() {
		presentLoading()
		apiClient.get('/api/get/profile/collection',
		{
			headers: {
			'Authorization':  `Bearer ${store.state.authToken}`
		}}).then(response => {
			//userCollection.value = response.data
			for(var i=0;i<response.data.length;i++) {
				var location = null
				if(response.data[i].location != null && response.data[i].location != '') {
				location = response.data[i].location
			}

			userCollection.value.push({
				id: response.data[i].id,
				name: response.data[i].name,
				username: response.data[i].username,
				profilePhoto: response.data[i].profile_photo,
				location: location,
				profile_type: response.data[i].profile_type
			})
		}}).catch(error => {
		console.log(error)
		});        
	}

	function searchUsersByKeyword() {
		if(keyword.value != null && keyword.value != '') {
			matchedItems.value = []
			var str = keyword.value
			for(var i=0; i<userCollection.value.length;i++) {
				var username = userCollection.value[i].username.toLowerCase()
				var name = userCollection.value[i].name.toLowerCase()
				str = str.toLowerCase()

				if(username.includes(str) || name.includes(str)) {
					matchedItems.value.push({
						id: userCollection.value[i].id,
						name: userCollection.value[i].name,
						username: userCollection.value[i].username,
						profilePhoto: userCollection.value[i].profilePhoto,
						location: userCollection.value[i].location,
						profile_type: userCollection.value[i].profile_type                
					})
				}
			}
		}     
	}

	function sendTo(user) {
		selectedUsers.value.push({
			id: user.id,
			name: user.name,
			username: user.username,
			avatar: user.profilePhoto
		})
		keyword.value = null
		matchedItems.value = []
	}

	function clearSearch() {
		keyword.value = null
		matchedItems.value = []
	}

	function removeUser(index) {
		selectedUsers.value.splice(index,1)
	}

	return {
		router, store, authUser, userCollection, close, keyword, matchedItems, searchUsersByKeyword, selectedUsers, sendTo, closeCircle, clearSearch, removeUser
	}
  },
	methods: {

	},
	watch: {
		keyword: function() {
			if(this.keyword == '') {
				this.keyword = null
			}
			if(this.keyword === null) {
				this.matchedItems = []
			} else {
				this.searchUsersByKeyword()
			}
		},
		selectedUsers: {
			handler() {
				this.$emit('selected-users', this.selectedUsers)
			},
			deep:true
		}
	}
});
</script>