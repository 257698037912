<template>
  <div>
    <!-- Subject -->
    <ion-item>
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-input v-model="subject" type="text" placeholder="Subject"></ion-input>
    </ion-item>
    <!-- Body -->
    <ion-item>
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-textarea v-model="body" placeholder="Message" />
    </ion-item>
    <!-- Action -->
    <ion-item v-if="body != null && body != ''" lines="none">
      <div tabindex="0"></div><!-- bug fix for safari 14 -->
      <ion-button slot="end" @click.prevent="sendMessage()">Send</ion-button>
    </ion-item>
  </div>
</template>

<script>
import { loadingController, toastController, IonItem, IonButton, IonInput, IonTextarea } from '@ionic/vue';
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../services/api';

export default defineComponent({
  props: ['username', 'selectedUsers'],
  name: 'SendMessageForm',
  components: { IonItem, IonButton, IonInput, IonTextarea },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const userId = ref(0);
    const subject = ref(null);
    const body = ref(null);
    const timeout = { default: 6000 }
    const toastMessage = ref(null);
    const receiverIds = ref([]);

    onMounted(() => {
      if(props.username != null && props.username != undefined) {
        getUserId()
      }
    })

    function goBack() {
      router.go(-1)
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    function getUserId() {
      console.log('asdfadsf')
      presentLoading()

      apiClient.get('/api/get/user_id/'+props.username,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          userId.value = response.data
        }).catch(error => {
          console.log(error)
        });  
    }

    function sendMessage() {
      if(body.value != null && body.value != '') {
        presentLoading()
        apiClient.post('/api/post/message', 
          { 
            sender_id: authUser.value.id,
            receiver_ids: receiverIds.value,
            parent_id: 0,
            subject: subject.value,
            body: body.value
          },
          {
            headers: {
              'Authorization':  `Bearer ${store.state.authToken}`
            }
          }).then(response => {
            if(response.data.message == 'success') {
              toastMessage.value = 'Your message has been sent'
              subject.value = null
              body.value = null
              userId.value = 0
              receiverIds.value = []
              goBack()
            }
            if(response.data.message == 'fail') {
              toastMessage.value = 'Something went wrong.'
            }
            if(response.data.message == 'fail-validation') {
              toastMessage.value = 'You are not authorized to do that.'
            }
            if(response.data.message == 'fail-auth') {
              toastMessage.value = 'You must be signed in.'
            }
            openToast()
          }).catch(error => {
            console.log(error)
          });
      }
    }

    return {
      router, goBack, store, authUser, sendMessage, subject, body, receiverIds, userId, getUserId
    }
  },
  methods: {

  },
  watch: {
    username: function() {
      if(this.username != null && this.username != undefined) {
        this.getUserId()
      }
    },
    userId: function() {
      if(this.userId > 0) {
        this.receiverIds.push(this.userId)
      }
    },
    selectedUsers: {
      handler() {
        this.receiverIds = []
        if(this.selectedUsers.length > 0) {
          for(var i=0;i<this.selectedUsers.length;i++) {
            this.receiverIds.push(this.selectedUsers[i].id)
          }
        }
      },
      deep:true
    }
  }
});
</script>

<style scoped>

</style>